import React, { useState, useEffect } from "react";
import "./Home.css";
import Footer from "../Footer";
import casos from "./Casos.json";
import { Redirect } from "react-router-dom";

import $ from "jquery";
import UsePageMetadata from "../../hooks/UsePageMetadata";

const ListaCasos = ({ white }) => {


  UsePageMetadata('Nuestros proyectos | INDIO Agencia Digital' ?? '');

  const [page, changePage] = useState(null);
  const [pageUrl, changePageUrl] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    $(window).scroll(function () {
      let scroll = $(window).scrollTop();
      let objectSelect2 = $(".scroll_white");
      let objectPosition2 = objectSelect2.offset().top - 600;

      let objectSelect = $(".scroll_animacion");
      let objectPosition = objectSelect.offset().top - 600;

      if (scroll >= objectPosition && scroll <= objectPosition2) {
        $(".casos_fixed").addClass("fadein");
      } else {
        $(".casos_fixed").removeClass("fadein");
      }
    });

    $(window).scroll(function () {
      let scroll = $(window).scrollTop();
      let objectSelect = $(".scroll_animacion");
      let objectPosition = objectSelect.offset().top - 400;
      if (scroll > objectPosition) {
        $(".contenedor_casos").addClass("animacion_li");
      } else {
        $(".contenedor_casos").removeClass("animacion_li");
      }
    });
  }, []);

  const animation = () => {
    const fondo_blanco = document.querySelector(".fondo_blanco");
    fondo_blanco.classList.add("fondo_blanco_animation");
    const fondo_blanco_top = document.querySelector(".fondo_blanco_top");
    fondo_blanco_top.classList.add("fondo_blanco_animation_top");
  };

  const showCase = () => {
    setTimeout(function () {
      changePage("change");
    }, 2500);
  };

  if (page === "change") {
    return <Redirect to={`/proyecto/${pageUrl}`} />;
  }

  return (
    <section>
      <section id="home" className="max-width">
        <span className="casos_fixed">— Casos</span>

        <div className="detalles">Conozca algunos de nuestros trabajos.</div>
        <div className="clearfix"></div>

        <div className="scroll_animacion"></div>
        <div class="scroll_animacion_2"></div>
        <ul className="contenedor_casos">
          {/* Unión */}
          {casos.map((caso, i) => (
            <li
              key={`caso_${i + 5}`}
              className="caso"
              id={caso.nombre}
              onClick={() => {
                showCase(`${caso.nombre}`);
                changePageUrl(`${caso.nombre}/${i + 1}`);
                animation();
              }}
            >
              <div className="titulo">
                <p>
                  <span>
                    / {i + 1 <= 9 && "0"} {i + 1}{" "}
                  </span>{" "}
                  {caso.title_home}
                </p>
                <div className="descripcion">
                  <span>{caso.description} </span>
                  <br /> <span>Ver más</span>
                </div>
              </div>

              <div className="imagen">
                <img
                  src={`https://indio.guru/assets_indio/${caso.img}`}
                  alt="Caso"
                />
              </div>
            </li>
          ))}
        </ul>

        <div className="fondo_blanco"></div>
        <div className="fondo_blanco_top"></div>
        <div className="clearfix"></div>
      </section>
      <Footer white={white} />
    </section>
  );
};

export default ListaCasos;
