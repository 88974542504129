import React, { useEffect } from 'react';

import ListaCasos from '../Home/ListaCasos';
import flecha_abajo from '../../assets/img/Home/flecha-abajo.png';
import './Home.css';
import UsePageMetadata from '../../hooks/UsePageMetadata';

const Home = ({ white, guardarWhite, guardarWhiteCaso, mostrarMenu }) => {
  UsePageMetadata('INDIO | Agencia Digital' ?? '');

  useEffect(() => {
    window.scrollTo(0, 0);
    guardarWhite(false);
    guardarWhiteCaso(false);
    mostrarMenu(false);
  }, [guardarWhiteCaso, guardarWhite, mostrarMenu]);

  return (
    <section className='home_animacion '>
      <div className='animacion_contenedor'>
        <div className='animacion'>
          <svg viewBox='0 0 397.75 33.5'>
            <defs>
              <linearGradient id='a' x1='89.75' x2='397.75' y1='5.75' y2='5.75' gradientUnits='userSpaceOnUse'>
                <stop offset='0' stopColor='#24748a' />
                <stop offset='1' stopColor='#ef7a90' />
              </linearGradient>
              <linearGradient id='d' x1='357.596' x2='391.096' y1='55.961' y2='56.036' gradientUnits='userSpaceOnUse'>
                <stop offset='0' stopColor='#24748a' />
                <stop offset='.31' stopColor='#60768c' />
                <stop offset='1' stopColor='#ef7a90' />
              </linearGradient>
            </defs>
            <g fill='none' strokeMiterlimit='10' strokeWidth='1.5'>
              <path className='home_lineas_1' stroke='url(#a)' d='M89.75 5.75h308' />
              <path className='home_lineas_1' stroke='url(#a)' d='M89.75 16.75h308' />
              <path className='home_lineas_1' stroke='url(#a)' d='M89.75 27.75h308' />
            </g>
            <path
              className='home_lineas_2'
              fill='url(#d)'
              d='M375 39.25A16.75 16.75 0 10391.75 56 16.769 16.769 0 00375 39.25zM359.75 56a15.256 15.256 0 0114.5-15.212v30.424A15.256 15.256 0 01359.75 56zm16 15.212V40.788a15.229 15.229 0 010 30.424z'
              transform='translate(-358.25 -39.25)'
            />
          </svg>

          <div className='texto movil'>
            <p>Creamos</p>
            <p>experiencias</p>
            <p>memorables</p>
            <p>en entornos</p>
            <p>digitales.</p>
          </div>

          <div className='texto web'>
            <p>Creamos</p>
            <p>
              <span>experiencias</span> <span>memorables</span>
            </p>
            <p>en entornos digitales.</p>
          </div>
        </div>
        <div className='clearfix'></div>

        <div id='trabajos' className='flecha_abajo'>
          <img src={flecha_abajo} alt='flecha' />
          <div>=deslice hacia abajo”</div>
        </div>
      </div>
      <ListaCasos />
    </section>
  );
};

export default Home;
