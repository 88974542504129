import React, { useEffect } from 'react';
import './Acerca.css';
import Staff from '../Staff/Staff';
import Footer from '../Footer';

import flecha_abajo from '../../assets/img/Home/flecha-abajo.png';
import boton_flecha_siguiente from '../../assets/img/boton_flecha_siguiente.png';

import $ from 'jquery';
import UsePageMetadata from '../../hooks/UsePageMetadata';

const Acerca = ({ white, guardarWhite, guardarWhiteCaso }) => {
  UsePageMetadata('Nosotros | INDIO Agencia Digital' ?? '');

  useEffect(() => {
    window.scrollTo(0, 0);

    // Scroll para about
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $('.scroll_white');
      var objectPosition = objectSelect.offset().top - 500;
      if (scroll >= objectPosition) {
        $('.about_fixed').addClass('change_white_color');
      } else {
        $('.about_fixed').removeClass('change_white_color');
      }
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $('.scroll_black');
      var objectPosition = objectSelect.offset().top - 600;
      if (scroll >= objectPosition && $('.about_fixed').hasClass('change_white_color')) {
        $('.about_fixed').removeClass('change_white_color');
      }
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $('.scroll_animacion');
      var objectPosition = objectSelect.offset().top - 700;
      if (scroll >= objectPosition) {
        $('.about_fixed').addClass('fadeout');
      } else {
        $('.about_fixed').removeClass('fadeout');
      }
    });

    // scroll para linea color manifesto
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $('.scroll_white');
      var objectPosition = objectSelect.offset().top - 300;
      if (scroll >= objectPosition) {
        $('.linea_color').addClass('linea_color_top_5');
      } else {
        $('.linea_color').removeClass('linea_color_top_5');
      }
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var objectSelect = $('.scroll_white');
      var objectPosition = objectSelect.offset().top - 0;
      if (scroll >= objectPosition) {
        $('.linea_color').addClass('linea_color_top_9');
      } else {
        $('.linea_color').removeClass('linea_color_top_9');
      }
    });

    guardarWhite(false);
    guardarWhiteCaso(false);
  }, [guardarWhite, guardarWhiteCaso]);

  const animation = () => {
    const fondo_blanco = document.querySelector('.fondo_blanco');
    fondo_blanco.classList.add('fondo_blanco_animation');
    const fondo_blanco_top = document.querySelector('.fondo_blanco_top');
    fondo_blanco_top.classList.add('fondo_blanco_animation_top');
  };

  const nextCase = (caso) => {
    const flecha_imagen = document.querySelector('.next_caso img');
    flecha_imagen.classList.add('flecha_caso_animacion');
    setTimeout(function () {
      window.location.href = `https://indio.guru/contacto`;
    }, 2600);
  };

  return (
    <section id='acerca'>
      <span className='about_fixed'>-Acerca</span>

      <div className='acerca_header'>
        <div className='about'>
          <span className='pink mt_pink'>/ Rétenos /</span>

          <hr />
          <span>la complacencia no nos hace mejores</span>
        </div>
        <div className='flecha_abajo'>
          <img src={flecha_abajo} alt='flecha' />
        </div>
      </div>

      <div className='galeria max-width'></div>

      <div className='scroll_white'></div>

      <div className='bg_black'>
        <div className='manifesto'>
          <div className='text_manifesto'>
            <p>
              En Indio agencia web somos artistas. Creemos que la tecnología en los productos digitales es un medio para llegar a soluciones altamente creativas que amplíen y complementen el espíritu de una marca. Así hemos construído nuestra agencia
              digital.
            </p>
          </div>

          <div className='indio border_color'>
            <div>indio</div>
          </div>

          <div className='linea_color_k'></div>
          <div className='content_manifesto '>
            <h2>Manifesto</h2>

            <ul>
              <li>
                <div className='title_manifesto'>
                  <div>/01</div>
                  <div>"Internet se creó para equivocarse"</div>
                </div>
                <div className='desc_manifesto'>Somos arriesgados y aprendemos equivocándonos lo más rápido posible.</div>
              </li>

              <li>
                <div className='title_manifesto'>
                  <div>/02</div>
                  <div>"Trabajamos para sus clientes"</div>
                </div>
                <div className='desc_manifesto'>Nos pondremos en sus zapatos de vez en cuando.</div>
              </li>

              <li>
                <div className='title_manifesto'>
                  <div>/03</div>
                  <div>"Rétenos"</div>
                </div>
                <div className='desc_manifesto'>La complacencia no nos hace mejores.</div>
              </li>

              <li>
                <div className='title_manifesto'>
                  <div>/04</div>
                  <div>"No somos proveedores"</div>
                </div>
                <div className='desc_manifesto'>Creemos en el poder de las alianzas y el trabajo en equipo.</div>
              </li>

              <li>
                <div className='title_manifesto'>
                  <div>/05</div>
                  <div>"Confíe en nosotros"</div>
                </div>
                <div className='desc_manifesto'>Si nos contrata, es porque sabemos hacer algo que usted no.</div>
              </li>
              <li>
                <div className='title_manifesto'>
                  <div>/06</div>
                  <div>"Somos 100% digitales"</div>
                </div>
                <div className='desc_manifesto'>Trabajamos desde cualquier lugar del mundo.</div>
              </li>
              <li>
                <div className='title_manifesto'>
                  <div>/07</div>
                  <div>"Páguenos bien"</div>
                </div>
                <div className='desc_manifesto'>Nuestro trabajo va a impactar sus ingresos. Invierta en el futuro de su organización.</div>
              </li>
            </ul>

            <div className='clearfix'></div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className='scroll_black'></div>

      <div className='max-width'>
        <Staff />
      </div>

      <div
        onClick={() => {
          nextCase('acerca');
          animation();
        }}
        className='next_caso'
      >
        <img className='flecha_caso_animacion' src={boton_flecha_siguiente} alt='siguiente' />
      </div>
      <div class='scroll_animacion_2'></div>
      <div className='fondo_blanco fondo_negro'></div>
      <div className='fondo_blanco_top fondo_negro'></div>
      <Footer white={white} />
    </section>
  );
};

export default Acerca;
