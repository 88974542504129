import React from 'react';
import TopSection from '../../sections/topSection/TopSection';
import desing_img from '../../../../assets/img/services/design.svg';
import OurClients from '../../sections/ourClients/OurClients';
import BlockTextTwo from '../../sections/blockTextTwo/BlockTextTwo';
import info_lines from './information/lineInformation.json';
import Lines from '../../sections/lines/Lines';
import BlockTextOne from '../../sections/blockTextOne/BlockTextOne';
import TestimoniesLayout from '../../sections/testimonies/TestimoniesLayout';
import ForWho from '../../sections/forWho/ForWho';
import Contacto from '../../../Contacto/Contacto';
import SomeOurProjects from '../../sections/someOurProjects/SomeOurProjects';

import img_one_feb from '../../../../assets/img/projects/proyectoos_ux/1_fep.png';
import img_one_movistar from '../../../../assets/img/projects/proyectoos_ux/2_movistar_arena.png';
import img_one_productos from '../../../../assets/img/projects/proyectoos_ux/3_pa.png';
import img_one_sama from '../../../../assets/img/projects/proyectoos_ux/4_sama.png';
import img_one_cordillera from '../../../../assets/img/projects/proyectoos_ux/5_cordillera.png';

import logo_movistar from '../../../../assets/img/logos_blancos/6_movistar.png';
import logo_paramo from '../../../../assets/img/logos_blancos/5_paramopresenta.png';
import logo_productos from '../../../../assets/img/logos_blancos/7_pa.png';
import logo_sama from '../../../../assets/img/logos_blancos/8_sama.png';
import UsePageMetadata from '../../../../hooks/UsePageMetadata';

const Desing = ({ guardarWhite, guardarWhiteCaso }) => {
  UsePageMetadata('Diseño UX/ UI | INDIO Agencia Digital'?? "");
  return (
    <div>
      <TopSection
        title='Diseño UX/UI'
        paragraph='Durante la etapa de diseño de experiencia de usuario y de la interfaz de usuario definimos la estructura, las lógicas de uso y navegación, las reglas visuales y finalmente creamos un prototipo del producto con el que podemos realizar pruebas de usabilidad con clientes y usuarios potenciales antes de enviar a nuestro equipo de desarrollo.'
        img={desing_img}
      />

      <OurClients />

      <BlockTextTwo
        type='black'
        text=' <span>/Somos artistas</span> <br/>  Creemos que la tecnología en el entorno web es un medio para llegar a una solución altamente creativa y estéticamente imponente, creando una experiencia web memorable y a la medida./'
      />

      <Lines info={info_lines} />

      <BlockTextOne text='/Nos gustan los <span> retos. </span>  Sabemos que la complacencia no nos hace mejores./' />

      <TestimoniesLayout />

      <ForWho />
      <SomeOurProjects type={2} info={information_slider} />

      <BlockTextTwo text='/Nuestro <span> equipo </span>  es confiable y arriesgado. Se ha especializado en crear entornos web bellos y poderosos./' />
      <div className='scroll_black'></div>
      <div className='scroll_white'></div>

      <div id='contact'>
        <Contacto guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} isWhite={true} bg_white={false} />
      </div>
    </div>
  );
};

export default Desing;

const information_slider = [
  {
    name: 'Festival Estéreo Picnic 2024',
    imgs: img_one_feb,
    logo: logo_paramo,
  },
  {
    name: 'Movistar Arena',
    imgs: img_one_movistar,
    logo: logo_movistar,
  },
  {
    name: 'Productos Arquitectónicos',
    imgs: img_one_productos,
    logo: logo_productos,
  },
  {
    name: 'Sama Sacred Music',
    imgs: img_one_sama,
    logo: logo_sama,
  },
  {
    name: 'Festival Cordillera',
    imgs: img_one_cordillera,
    logo: logo_paramo,
  },
];
