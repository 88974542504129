import React, { useState, useEffect } from 'react';
import './Casos.css';

import icono_gif from '../../assets/img/casos/icono-gif.png';
import icono_video from '../../assets/img/casos/icono-video.png';
import icono_imagenes from '../../assets/img/casos/icono-imagenes.png';
import boton_flecha_siguiente from '../../assets/img/boton_flecha_siguiente.png';

import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import casos from '../Home/Casos.json';
import { useParams } from 'react-router-dom';

import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import Footer from '../Footer';
import UsePageMetadata from '../../hooks/UsePageMetadata';

const Caso = ({ guardarWhite }) => {
  let { name, number } = useParams();
  const [page, changePage] = useState(null);
  const [caso, setCaso] = useState([]);
  const [nexPage, setNexPage] = useState({});

  useEffect(() => {
    if (caso.length > 0) {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var objectSelect = $('.scroll_animacion');
        var multimedia_contenedor = $('.scroll_animacion_2');
        var objectPosition = objectSelect.offset().top - 250;
        let multimediaPosition = multimedia_contenedor.offset().top;
        if (scroll >= objectPosition && scroll <= multimediaPosition) {
          $('.number').addClass('rotate_number');
        } else {
          $('.number').removeClass('rotate_number');
        }
        if (scroll >= objectPosition && scroll >= multimediaPosition) {
          $('.number').removeClass('rotate_number');
        }
      });
    }

    guardarWhite(true);
  }, [guardarWhite, caso]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const resultado = casos.filter((caso) => {
      return caso.nombre === name;
    });

    setCaso(resultado);

    if (number < casos.length) {
      setNexPage(casos[number]);
    }

    const fondo_blanco = document.querySelector('.fondo_blanco');
    const fondo_blanco_top = document.querySelector('.fondo_blanco_top');
    fondo_blanco.classList.remove('fondo_blanco_animation');
    fondo_blanco_top.classList.remove('fondo_blanco_animation_top');
  }, [name, number]);

  useEffect(() => {
    if (caso.length > 0) {
      const numberInter = document.querySelector('.number');
      numberInter.classList.remove('rotate_number');

      const header_caso = document.querySelector('.header_caso');
      const header_content = document.querySelector('.header_content');
      const multimedia_contenedor = document.querySelector('.multimedia_contenedor');
      const body_caso = document.querySelector('.body_caso');

      header_caso.classList.add('header_caso_animation');
      header_content.classList.add('fadeIn_1');
      multimedia_contenedor.classList.add('fadeIn_2');
      body_caso.classList.add('fadeIn_2');
    }
  }, [name, number, caso]);

  const animation = () => {
    const fondo_blanco = document.querySelector('.fondo_blanco');
    const header_caso = document.querySelector('.header_caso');

    const header_content = document.querySelector('.header_content');
    const multimedia_contenedor = document.querySelector('.multimedia_contenedor');
    const body_caso = document.querySelector('.body_caso');

    fondo_blanco.classList.add('fondo_blanco_animation');
    const fondo_blanco_top = document.querySelector('.fondo_blanco_top');
    fondo_blanco_top.classList.add('fondo_blanco_animation_top');

    setTimeout(() => {
      window.scrollTo(0, 0);
      header_caso.classList.remove('header_caso_animation');

      header_content.classList.remove('fadeIn_1');
      multimedia_contenedor.classList.remove('fadeIn_2');
      body_caso.classList.remove('fadeIn_2');
    }, 2400);
  };

  const nextCase = (caso) => {
    const flecha_imagen = document.querySelector('.next_caso img');
    flecha_imagen.classList.add('flecha_caso_animacion');
    setTimeout(function () {
      if (caso === 'acerca') {
        changePage(caso);
      } else {
        window.location.href = `https://indio.guru/#/proyecto/${nexPage.nombre}/${parseInt(number) + 1}`;
      }
    }, 2600);
  };

  if (page === 'acerca') {
    return <Redirect to={`/nosotros`} />;
  }

  UsePageMetadata(caso[0]?.title_seo ?? '');

  return (
    <section className='caso_modal '>
      {caso.length > 0 && (
        <>
          {caso.map((caso) => (
            <div>
              <div className='contenedor_caso' id={`${caso.nombre}_caso`}>
                <div
                  style={{
                    background: `url(https://indio.guru/assets_indio/casos/${caso.banner.img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: caso.banner.position,
                  }}
                  className='header_caso'
                ></div>

                <div className='contenedor_fadeOut'>
                  <div className='header_content '>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                      {caso.title}
                    </ReactMarkdown>

                    <p>{caso.description} </p>
                  </div>

                  <div className='clearfix'></div>

                  <div className='body_caso'>
                    <div className='scroll_animacion'></div>

                    <div className='content_body'>
                      <p className='lateral'>—Caso</p>
                      <p className='number'>{number} </p>

                      <div className='separador_content_body'>
                        <div className='center'>
                          <b>Brief</b>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                            {caso.brief}
                          </ReactMarkdown>
                        </div>
                      </div>

                      {caso.constribucion !== '' && (
                        <div className='separador_content_body'>
                          <div className='center'>
                            <b>
                              Nuestra <span>constribución</span>
                            </b>
                            <div>
                              <ul className='font_bold'>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                                  {caso.constribucion}
                                </ReactMarkdown>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='scroll_animacion_2'></div>

                  <div className='multimedia_contenedor'>
                    <div className='separador_multimedia'>
                      <div className='imagenes'>
                        {caso.galeria.length > 0 && (
                          <>
                            {caso.galeria.map((galeria) => (
                              <>
                                {galeria.tipo === 'normal' ? (
                                  <>
                                    {galeria.imagenes.map((img) => (
                                      <img style={{ width: img.width }} src={`https://indio.guru/assets_indio/casos/${img.img}`} alt='caso' loading='lazy' />
                                    ))}
                                  </>
                                ) : (
                                  <div className='all_gallery_flex'>
                                    {galeria.imagenes.map((img) => (
                                      <img src={`https://indio.guru/assets_indio/casos/${img.img}`} alt='caso' loading='lazy' />
                                    ))}
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {caso.galeria.length > 0 && (
                          <>
                            {caso.galeria.map((galeria) => (
                              <>
                                {galeria.tipo === 'normal' ? (
                                  <>
                                    {galeria.videos.map((img) => (
                                      <>
                                        <video muted playsInline autoPlay loop style={{ width: img.width }}>
                                          <source src={`https://indio.guru/assets_indio/casos/${img.img}`} type='video/mp4' />
                                        </video>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <div className='all_gallery_flex'>
                                    {galeria.videos.map((img) => (
                                      <video muted playsInline autoPlay loop>
                                        <source src={`https://indio.guru/assets_indio/casos/${img.img}`} type='video/mp4' />
                                      </video>
                                    ))}
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </div>

                      <div className='icons_multimedia'>
                        <div className='icons'>
                          <div className='icon'>
                            <img src={icono_gif} alt='icono' />
                          </div>
                          <div className='icon'>
                            <img src={icono_video} alt='icono' />
                          </div>

                          <div className='icon'>
                            <img src={icono_imagenes} alt='icono' />
                          </div>
                        </div>

                        <div className='galeria'>
                          <span className='lateral'>multimedia </span>
                          <span className='lateral'>Galería </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {number < casos.length ? (
                <div
                  onClick={() => {
                    nextCase('next');
                    animation();
                  }}
                  className='next_caso'
                >
                  <img className='flecha_caso_animacion' src={boton_flecha_siguiente} alt='siguiente' />

                  <p>=“ver siguiente caso”</p>
                </div>
              ) : (
                <div
                  onClick={() => {
                    nextCase('acerca');
                    animation();
                  }}
                  className='next_caso'
                >
                  <img className='flecha_caso_animacion' src={boton_flecha_siguiente} alt='siguiente' />

                  <p>=“ir a Nosotros</p>
                </div>
              )}
            </div>
          ))}
        </>
      )}
      <div className='fondo_blanco'></div>
      <div className='fondo_blanco_top'></div>
      <Footer />
    </section>
  );
};

export default Caso;
