import React from 'react';
import TopSection from '../../sections/topSection/TopSection';
import benchmarking_img from '../../../../assets/img/services/benchmarking.svg';
import OurClients from '../../sections/ourClients/OurClients';
import BlockTextOne from '../../sections/blockTextOne/BlockTextOne';
import Lines from '../../sections/lines/Lines';

import info_lines from './information/line_information.json';
import BlockTextTwo from '../../sections/blockTextTwo/BlockTextTwo';
import ForWho from '../../sections/forWho/ForWho';
import Range from '../../sections/range/Range';
import Contact from '../../../Contacto/Contacto';
import SomeOurProjects from '../../sections/someOurProjects/SomeOurProjects';

// import imgOne_terpel from '../../../../assets/img/projects/auditorias/1_terpel.png';
import imgOne_movistar from '../../../../assets/img/projects/auditorias/2-movistar_arena.png';
import imgOne_venues from '../../../../assets/img/projects/auditorias/3_venues.png';
import imgOne_vagabond from '../../../../assets/img/projects/auditorias/4_vgbd.png';
import imgOne_marivi from '../../../../assets/img/projects/auditorias/5_marivi.png';

import logo_movistar from '../../../../assets/img/logos_blancos/6_movistar.png';
// import logo_terpel from '../../../../assets/img/logos_blancos/1_terpel.png';
import logo_venues from '../../../../assets/img/logos_blancos/2_venues.png';
import logo_vg from '../../../../assets/img/logos_blancos/4_vgbnd.png';
import UsePageMetadata from '../../../../hooks/UsePageMetadata';

const BenchMarking = ({ guardarWhite, guardarWhiteCaso }) => {
  UsePageMetadata('Benchmarking | INDIO Agencia Digital' ?? "");

  return (
    <div>
      <div className='scroll_black'></div>

      <TopSection
        title='Benchmarking y auditorías'
        paragraph='Cada proyecto comienza con una pregunta. Nuestro trabajo en esta etapa es salir a buscar las pistas planteadas en el brief para encontrar las posibles respuestas y plantear una solución digital.'
        img={benchmarking_img}
      />

      <OurClients />

      <BlockTextOne text='/Nos gustan los <span> retos. </span>  Sabemos que la complacencia no nos hace mejores./' />

      <Lines info={info_lines} />

      <BlockTextTwo text='/Trabajamos para construir una <span> relación de largo plazo </span>  basada en la confianza con nuestros clientes, en donde tanto nuestras habilidades como nuestras limitaciones son transparentes. /' />
      <div className='scroll_white'></div>
      <ForWho />
      <SomeOurProjects type={1} info={information_slider} />
      <Range />

      <div id='contact'>
        <Contact guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} isWhite={true} bg_white={false} />
      </div>
    </div>
  );
};

export default BenchMarking;

const information_slider = [
  // {
  //   name: 'Auditoría de heurísticas UX Terpel',
  //   imgs: imgOne_terpel,
  //   logo: logo_terpel,
  // },
  {
    name: 'Auditoría SEO Movistar Arena',
    imgs: imgOne_movistar,
    logo: logo_movistar,
  },
  {
    name: 'Auditoría SEO Venues Snacks',
    imgs: imgOne_venues,
    logo: logo_venues,
  },
  {
    name: 'Auditoría SEO Vagabond',
    imgs: imgOne_vagabond,
    logo: logo_vg,
  },
  {
    name: 'Auditoría SEO Marivi',
    imgs: imgOne_marivi,
    logo: null,
  },
];
