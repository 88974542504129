import React from 'react';

import TopSection from '../../sections/topSection/TopSection';
import development_img from '../../../../assets/img/services/desarrollo.svg';
import OurClients from '../../sections/ourClients/OurClients';
import BlockTextTwo from '../../sections/blockTextTwo/BlockTextTwo';
import Lines from '../../sections/lines/Lines';

import info_lines from './information/line_information.json';
import BlockTextOne from '../../sections/blockTextOne/BlockTextOne';
import TestimoniesLayout from '../../sections/testimonies/TestimoniesLayout';
import ForWho from '../../sections/forWho/ForWho';
import Contacto from '../../../Contacto/Contacto';

import SomeOurProjects from '../../sections/someOurProjects/SomeOurProjects';

import imgOne_dpvida from '../../../../assets/img/projects/proyectos_desarrollo/1_deporvida.png';
import imgOne_picnic from '../../../../assets/img/projects/proyectos_desarrollo/3_fep.png';
import imgOne_movistar from '../../../../assets/img/projects/proyectos_desarrollo/4_movistar_arena.png';
import imgOne_diomedes from '../../../../assets/img/projects/proyectos_desarrollo/5_diomedes.png';

import logo_movistar from '../../../../assets/img/logos_blancos/6_movistar.png';
import logo_warner from '../../../../assets/img/logos_blancos/11_warner.png';
import logo_paramo from '../../../../assets/img/logos_blancos/5_paramopresenta.png';
import logo_sony from '../../../../assets/img/logos_blancos/13_sony.png';
import UsePageMetadata from '../../../../hooks/UsePageMetadata';

const DevelopmentWeb = ({ guardarWhite, guardarWhiteCaso }) => {
  UsePageMetadata('Desarrollo web | INDIO Agencia Digital' ?? "");
  
  return (
    <section>
      <TopSection
        title='Desarrollo web'
        paragraph='Nuestro equipo de desarrollo es detallista, riguroso y accesible. Se ha especializado en cuidar los procesos de front-end para construir interfaces de usuario fieles al diseño UI, sin dejar a un lado la importancia de tener un back-end poderoso que cumpla con las funcionalidades y requerimientos de desempeño esperados.'
        img={development_img}
      />

      <OurClients />

      <BlockTextTwo
        type='black'
        text='/Nos hemos propuesto quitar el velo Que existe entre el mundo y los desarrolladores, hablando en un <span> lenguaje que todos entendemos./</span> '
      />

      <Lines info={info_lines} />

      <BlockTextOne
        type={2}
        text='/Trabajamos para construir una relación de largo plazo basada en la <span> confianza con nuestros clientes, </span> en donde tanto nuestras habilidades como nuestras limitaciones son transparentes. /'
      />

      <TestimoniesLayout />
      <ForWho />
      <SomeOurProjects info={information_slider} type={2} />

      <div className='scroll_black'></div>
      <div className='scroll_white'></div>

      <div id='contact'>
        <Contacto guardarWhite={guardarWhite} guardarWhiteCaso={guardarWhiteCaso} isWhite={true} bg_white={false} />
      </div>
    </section>
  );
};

export default DevelopmentWeb;

const information_slider = [
  {
    name: 'Desarrollo web: De por Vida x Doble Porción (Warner Music)',
    imgs: imgOne_dpvida,
    logo: logo_warner,
  },
  {
    name: 'Festival Estéreo Picnic 2024',
    imgs: imgOne_picnic,
    logo: logo_paramo,
  },
  {
    name: 'Movistar Arena',
    imgs: imgOne_movistar,
    logo: logo_movistar,
  },
  {
    name: 'Filtro Diomedes Díaz (Sony Music)',
    imgs: imgOne_diomedes,
    logo: logo_sony,
  },
];
